$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixHeights() {
		$(".RotatorTestimonialContent").sameHeight();
		$(".featured-service__panel").sameHeight();
	}

	setTimeout(fixHeights, 500);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 500);
	});

});

